html {
    background-color: #ffffff;
    box-sizing: border-box;
}

body {
    font-family: 'Lato', sans-serif;
}


.body-wrapper {
    overflow: hidden;
}

*,
*:before,
*:after {
    box-sizing: inherit;
    padding: 0;
    margin: 0;
}

.container {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 576px) {
    .container {
        max-width: 540px;
    }
}
@media (min-width: 768px) {
    .container {
        max-width: 720px;
    }
}
@media (min-width: 992px) {
    .container {
        max-width: 960px;
    }
}
@media (min-width: 1200px) {
    .container {
        max-width: 1200px;
    }
}
@media (min-width: 1400px) {
    .container {
        max-width: 1200px;
    }
}

@media only screen and (max-width: 590px) {
.container {
    padding-left: 25px!important;
    padding-right: 25px!important;
}
}

h1 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 300;
    font-size: 56px;
    line-height: 66px;
    color: #404C52;
    margin-bottom: 40px;
}

h1 b {
    font-weight: 800;
}

h2 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 42px;
    line-height: 66px;
    color: #404C52;
    padding-bottom: 22px;
    padding-top: 75px;
}

p {
    font-family: 'Lato';
font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 32px;
color: #2E3C54;
}

p a {
    font-weight: bold;
    color: inherit;
    text-decoration: underline;
}

a {
    text-decoration: none;
}

li {
    list-style: none;
}

p:not(:last-child) {
    padding-bottom: 25px;
}



/* Banner */
.banner{
    background: #FFFFFF;
    box-shadow: 0px 4px 105px rgba(34, 48, 74, 0.2);
    border-radius: 0px 0px 10px 10px;
    padding: 10px 20px 10px 10px;
    display: grid;
    gap: 30px;
    align-items: center; 
    grid-template-columns: 0.8fr 2fr 0.7fr;
    margin-bottom: 25px;
  }
  .banner .logo-box{
    background: #EAF2F8;
    border-radius: 10px;
  }
  .banner .logo-box a{
    background: url(../image/domainer_logo.svg);
    background-repeat: no-repeat;
    background-position: center center;
    color: transparent;
    display: block;
    font-size: 0;
    padding: 20px 30px;
    height: 138px;
  }
  .banner .banner-title{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 30px;
    margin-bottom: 15px;
    color: #090909;
  }
  .banner .info{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #7D7F82;
    position: relative;
    margin-left: 15px;
    background-color: transparent;
    padding: 0;
  }
  .banner .info:before{
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 6px;
    height: 100%;
    margin-left: -15px;
    border-radius: 10px;
    background: #F27558;
  }
  .banner .domaner-btn{
    background: #F27558;
    transition: all 0.5s ease-in-out;
    border-radius: 10px;
    padding: 23px 5px;
    display: block;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 22px;
    text-align: center;
    color: #000000;
  }
  .banner .domaner-btn:hover {
    background-color: rgb(249, 173, 141);
  }
  @media only screen and (max-width: 1090px) {
    .banner .banner-title{
        font-size: 22px;
        margin-bottom: 10px;
    }
    .banner .info{
        font-size: 18px;
        text-align: left!important;
    }
    .banner .domaner-btn{
        font-size: 18px;
    }
    .banner .domaner-btn {
        font-size: 18px;
        line-height: 22px;
        display: table;
        margin: 0 auto;
        padding: 23px 30px;
    }
  }
  @media only screen and (max-width: 940px) {
    .banner{
        grid-template-columns: 1fr;
        padding: 10px;
    }
    .banner .logo-box a{
        background: url(../image/domainer_logo_mob.svg);
        background-repeat: no-repeat;
        background-position: center center;
        height: 66px;
    }
    .banner .banner-title{
        line-height: 24px;
        text-align: center;
    }
    .banner .info{
        font-size: 16px;
        line-height: 20px;
        text-align: center!important;
    }
    .banner .info:before{
        content: none;
    }
  }
  @media only screen and (max-width: 440px) {
    .banner .domaner-btn {
        display: block;
        padding: 23px 0px;
    }
  }

.nav-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px 0;
}

.nav-bar {
    display: flex;
    justify-content: space-between;
    gap: 80px;
}

.nav-bar a {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
    color: #142645;
}

.site-logo {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #142645;
    position: relative;
    padding-left: 40px;
    display: flex;
    align-items: center;
}

.site-logo::before {
    position: absolute;
    content: "";
    background-image: url(../image/logo.png);
    background-size: contain;
    width: 34px;
    height: 40px;
    left: 0;
    background-repeat: no-repeat;
}

.hero-content {
    position: relative;
    z-index: 2;
    padding-bottom: 80px;
}

.site-header-inner {
    display: grid;
    grid-template-columns: 55% 1fr;
    padding-top: 55px;
    position: relative;
}

.site-header-inner::after {
    position: absolute;
    content: "";
    background-image: url(../image/clouds.png);
    width: 100%;
    height: 135px;
    background-size: cover;
    background-repeat: no-repeat;
    bottom: -30px;
}

.site-header-inner p {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 32px;
    color: #2E3C54;
}

.hero-img {
    position: relative;
}

.hero-img::before {
    position: absolute;
    content: "";
    background-image: url(../image/character.svg);
    width: 190px;
    height: 180px;
    background-size: contain;
    background-repeat: no-repeat;
    bottom: 0;
    left: 0;
    z-index: 2;
}

.hero-img::after {
    position: absolute;
    content: "";
    background-image: url(../image/hearts.svg);
    width: 120px;
    height: 35px;
    background-size: contain;
    background-repeat: no-repeat;
    bottom: 195px;
    left: 70px;
    z-index: 2;
}

.site-header .container {
    position: relative;
}

.site-header .container.hero::before {
    position: absolute;
    content: "";
    background-image: url(../image/tree.svg);
    background-size: contain;
    background-repeat: no-repeat;
    right: -90px;
    bottom: 0;
    width: 406px;
    height: 406px;
    z-index: 2;
}

/* TABLE  */

table,
.table {
    background: #FFFFFF;
    box-shadow: 0px 0px 33px rgba(145, 145, 145, 0.25);
    border-radius: 20px;
    border-spacing: 0 10px;
    padding: 9px;
    position: relative;
    width: 100%;
}

.axie {
    background-image: url(../image/axie.png); 
}
.sandbox {
    background-image: url(../image/sandbox.png);
    background-size: 166px !important;
}
.noa {
    background-image: url(../image/noa.png);
    background-size: 150px !important
}
.solchicks {
    background-image: url(../image/solchicks.png);
    background-size: 160px !important
}

.sidus {
    background-image: url(../image/sidus.png);
    background-size: 156px !important;
}

.cukies {
    background-image: url(../image/cukies.png);
    background-size: 146px !important;
}
.meli {
    background-image: url(../image/meli.png);
    background-size: 150px !important
}
.vox {
    background-image: url(../image/vox.png);
    background-size: 150px !important
}
.spider-tanks {
    background-image: url(../image/spider-tanks.png);
    background-size: 150px !important;
}
.echoes {
    background-image: url(../image/echoes-of-empire.png);
    background-size: 150px !important;
}
.fortified {
    background-image: url(../image/fortified-logo.png);
    background-size: 150px !important;
}
.kingdom-karnage {
    background-image: url(../image/kingdom-karnage.png);
    background-size: 85px !important;
}

.tank-battle {
    background-image: url(../image/tank-bottle.png);
    background-size: 105px !important
}
.universe-island {
    background-image: url(../image/universe-island.png);
    background-size: 150px !important;
}
.cryptoplants {
    background-image: url(../image/cryptoplants-club.png);
    background-size: inherit;
}
.the-mars {
    background-image: url(../image/the-mars-metaverse.png);
    background-size: 150px !important;
}
.nine-chronicles {
    background-image: url(../image/nine-chronicles.png);
    background-size: inherit;
}
.tinycolony {
    background-image: url(../image/tiny-colony.png);
    background-size: initial;
}
.battlehero {
    background-image: url(../image/battle-hero.png);
    background-size: 150px !important;
}
.herocat {
    background-image: url(../image/herocat.png);
    background-size: 150px !important;
}
.endersgate {
    background-image: url(../image/enders-gate.png);
    background-size: 150px !important;
}
.avalon {
    background-image: url(../image/avalon.jpeg);
}
.epichero {
    background-image: url(../image/epic-hero.png);
}
.webb {
    background-image: url(../image/worldwide-webb.png);
    background-size: 165px !important;
}
.mirandus {
    background-image: url(../image/mirandus.png);
    background-size: 150px !important;
}

.img a div {
    background-color: white;
    border-radius: 10px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 100%;
    height: 100px;
}

.left {
    text-align: left;
    padding-left: 35px;
}

thead th {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 30px;
    color: #758196;
    text-transform: uppercase;
    padding: 20px 20px;
}

.device-status {
    font-family: 'Lato';
font-style: normal;
font-weight: 400;
font-size: 17px;
line-height: 22px;
color: #142645;
}

.cta-table {
    padding: 17px 30px;
    background-color: #64AF6D;
    border-radius: 50px;
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
    color: #fff;
    z-index: 2;
    position: relative;
    transition: all 0.3s ease-in;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.cta-table:before {
    position: absolute;
    content: "";
    box-shadow: 0px 0px 0px 10px #afebb6;
    z-index: -1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 50px;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.cta-table:hover {
    background-color: #5BC868;
}

.cta-table:hover::before {
    opacity: 1;
}


/* .pading {
    padding-left: 60px;
} */

tbody tr {
    /* background-color: #ECF2F6; */
}

.img {
    position: relative;
}

.td {
    position: relative;
}

.td a div {
    color: transparent;
}

.intro .td .img::before {
    position: absolute;
    content: "";
    color: #fff;
    background-image: url(../image/orange-badge.svg);
    width: 20px;
    height: 25px;
    background-size: contain;
    background-repeat: no-repeat;
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 10px;
    top: -10px;
    padding-bottom: 3px;
    z-index: 2;
}

.first .img::before {
    content: "1" !important;
    color: #fff;
    background-image: url(../image/green-badge.svg) !important;
}

.intro .td:nth-of-type(2) .img::before {
    content: "2";
}
.intro .td:nth-of-type(3) .img::before {
    content: "3";
}
.intro .td:nth-of-type(4) .img::before {
    content: "4";
}
.intro .td:nth-of-type(5) .img::before {
    content: "5";
}
.intro .td:nth-of-type(6) .img::before {
    content: "6";
}
.intro .td:nth-of-type(7) .img::before {
    content: "7";
}
.intro .td:nth-of-type(8) .img::before {
    content: "8";
}
.intro .td:nth-of-type(9) .img::before {
    content: "9";
}
.intro .td:nth-of-type(10) .img::before {
    content: "10";
}

.m-slide-down .td:nth-of-type(1) .img::before {
    content: "11";
}
.m-slide-down .td:nth-of-type(2) .img::before {
    content: "12";
}
.m-slide-down .td:nth-of-type(3) .img::before {
    content: "13";
}
.m-slide-down .td:nth-of-type(4) .img::before {
    content: "14";
}
.m-slide-down .td:nth-of-type(5) .img::before {
    content: "15";
}
.m-slide-down .td:nth-of-type(6) .img::before {
    content: "16";
}
.m-slide-down .td:nth-of-type(7) .img::before {
    content: "17";
}
.m-slide-down .td:nth-of-type(8) .img::before {
    content: "18";
}
.m-slide-down .td:nth-of-type(9) .img::before {
    content: "19";
}
.m-slide-down .td:nth-of-type(10) .img::before {
    content: "20";
}
.m-slide-down .td:nth-of-type(11) .img::before {
    content: "21";
}
.m-slide-down .td:nth-of-type(12) .img::before {
    content: "22";
}
.m-slide-down .td:nth-of-type(13) .img::before {
    content: "23";
}
.m-slide-down .td:nth-of-type(14) .img::before {
    content: "24";
}
.m-slide-down .td:nth-of-type(15) .img::before {
    content: "25";
}



.activity-table .td:nth-of-type(1) .img::before {
    content: "1";
}
.activity-table .td:nth-of-type(2) .img::before {
    content: "2";
}
.activity-table .td:nth-of-type(3) .img::before {
    content: "3";
}
.activity-table .td:nth-of-type(4) .img::before {
    content: "4";
}
.activity-table .td:nth-of-type(5) .img::before {
    content: "5";
}
.activity-table .td:nth-of-type(6) .img::before {
    content: "6";
}
.activity-table .td:nth-of-type(7) .img::before {
    content: "7";
}
.activity-table .td:nth-of-type(8) .img::before {
    content: "8";
}
.activity-table .td:nth-of-type(9) .img::before {
    content: "9";
}
.activity-table .td:nth-of-type(10) .img::before {
    content: "10";
}
.m-slide-down {
    height: 0;
    overflow: hidden;
    -moz-transition: height .5s;
    -ms-transition: height .5s;
    -o-transition: height .5s;
    -webkit-transition: height .5s;
    transition: height .5s;
}
.read-more-1>*{
    pointer-events: none;
}
.read-more-1.close,  .collapse-1.close{
  display: none;
}
/* tr td:first-child { border-top-left-radius: 10px; }
tr td:last-child { border-top-right-radius: 10px; }
tr td:first-child { border-bottom-left-radius: 10px; }
tr td:last-child { border-bottom-right-radius: 10px; } */


.what-are {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 25px;
}

.what-are-img {
    background-image: url(../image/greeting.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom;
    margin-right: 50px;
}


/* TABLE ACTIVITY  */

#best-p2e-nft-games {
    text-align: center;
    padding: 90px 0 45px;
}

.alien {
    background-image: url(../image/alienworlds.webp);
    background-size: 150px !important;
}

.splinterlands {
    background-image: url(../image/splinterlands.png);
    background-size: 150px !important;
}

.bombcrypto {
    background-image: url(../image/bomb-crypto.png);
    background-size: 150px !important;
}

.cryptomines {
    background-image: url(../image/crpytomines.png);
    background-size: 66px !important;
}

.upland {
    background-image: url(../image/upland.png);
    background-size: 150px !important;
}

.defikingdoms {
    background-image: url(../image/defi-kingdoms.png);
    background-size: 150px !important;
}

.x-world {
    background-image: url(../image/x-world.svg);
    background-size: 150px !important;
}

.metaverse-miner {
    background-image: url(../image/metaverse-miner.png);
    background-size: 200px !important;
    background-position: calc(50% - -57px) !important;
}


.arc8 {
    background-image: url(../image/arc8.png);
    background-size: 77px !important
}

/* .activity .pading {
    max-width: 180px;
} */

.activity {
    position: relative;
    z-index: 3;
}

.earning {
    position: relative;
    z-index: 2;
    gap: 75px;
}

.earning::before {
    position: absolute;
    content: "";
    width: 1000%;
    height: 1180px;
    background-color: #64AF6D;
    z-index: -1;
    left: calc(50% - 300%);
    transform: rotate(4deg);
}


.img-earning {
    background-image: url(../image/character-2.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom;
}

.coins {
    background-image: url(../image/coins.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom;
}

.earning h2,
.earning p {
    color: #fff;
}


/* FAQ  */

.text-center h2{
    color: #000;
    font-weight: 300;
  }

.faq {
    background: #F6F6F6;
    border-radius: 10px;
    margin-bottom: 20px;
  }
  
  .faq-wrapper{
        position: relative;
        z-index: 10;
  }
  .faq-section-wrapper{
    padding-bottom: 90px;
  }
   /* .faq-heading {
    margin-bottom: 38px!important;
    padding-top: 68px!important
  } 
   */
   .faq-wrapper {
    padding-top: 250px;
    }
    .center {
        padding-bottom: 25px;
    }
  
  .faq.expanded+.faq {
    border-top: 0
  }
  
  
  .faq-title {
    align-items: center;
    display: flex;
    justify-content: space-between
  }
  
  .faq-title h3 {
    color: #000;
    font-size: 28px;
    font-weight: 400;
    height: 98px;
    line-height: 98px;
    pointer-events: none;
    margin-bottom: 0 !important;
    margin-top: 0;
    padding: 0;
  }
  .faq-btn {
    align-items: center;
    border: 0;
    border-radius: 25px;
    display: flex;
    font-size: 100px;
    font-weight: 200;
    height: 49px;
    justify-content: center;
    width: 49px;
  }
  
  
  
  .faq-btn:hover,.faq-title:hover {
    cursor: pointer
  }
  
  .faq {
    
    transition: background-color .2s linear,font-weight .2s linear,border-radius .2s linear;
    position: relative;
    padding-bottom: 0;
    background: #FFFFFF;
    box-shadow: 0px 0px 33px rgba(145, 145, 145, 0.25);
    border-radius: 20px;
    
  }
  .faq-background-outer{
    border: 1px solid #3B495F;
    position: absolute;
    border-radius: 40px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
  }
  .faq-content{
    z-index: 2;
    padding-left: 40px;
  }
  
  .faq .faq-body {
    color: #3B495F;
    font-size: 17px;
    line-height: 24px;
    padding-bottom: 44px;
    padding-top: 0px;
    width: 95%;
  }
  .faq.collapsed .faq-body {
    display: none
  }
  .faq-body a{
    font-weight: bold;
    color: #000;
    text-decoration: underline;
  }
  


  .faq.expanded {
      padding-top: 0px;
  }

  .faq.expanded h3 {
      font-weight: 700;
  }
  
  .faq-btn span {
    pointer-events: none
  }
  
  .faq.collapsed .faq-btn span {
    color: #427CEC
  }
  
  .faq.expanded .faq-btn span {
    color: #427CEC
  }
  
  .faq.collapsed .faq-btn span.plus,.faq.expanded .faq-btn span.minus {
    display: block;
  }
  .faq.collapsed .faq-btn span.minus,.faq.expanded .faq-btn span.plus {
    display: none
  }

  .faq.expanded .faq-title h3 {
    font-weight: 700
  }
  .text-center {
    text-align: center;
    color: #000;
  }
  .text-center p{
    padding-bottom: 50px;
    color: #000;
  }
  .faq-title h3{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 66px;
    color: #362B42;
    display: flex;
    align-items: center;
  }
  .faq-body p{
    text-align: left;
  } 
  .faq-wrapper h2,
    .center {
        text-align: center;
    }


.plus::before {
    position: absolute;
    content: "";
    background-image: url(../image/plus.svg);
    width: 50px;
    background-color: #64AF6D;
    height: 50px;
    background-position: center;
    background-repeat: no-repeat;
    top: 0;
    right: 0;
    border-radius: 0px 20px;
}

.minus {
    position: absolute;
    content: "";
    background-image: url(../image/minus.svg);
    width: 50px;
    background-color: #404C52;
    transition: all 0.2s;
    height: 50px;
    background-position: center;
    background-repeat: no-repeat;
    top: 0;
    right: 0;
    border-radius: 0px 20px;
}

.faq:first-of-type {
    margin-top: 25px;
}

/* ABOUT US  */

.about-us {
    background-color: #404C52;
    text-align: center;
    position: relative;
    z-index: 9;
    margin-top: -70px;
    padding: 100px 0;
}

.about-us h2,
.about-us p {
    color: #fff;
}

.contact-cta {
    padding: 18px 55px;
    background: #64AF6D;
    border-radius: 48px;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
    text-align: center;
    border: 0;
    color: #FFFFFF;
    position: relative;
    display: block;
    width: fit-content;
    margin: 0 auto;
}


footer .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0;
}

footer {
    background-color: #404C52;
}

footer,
footer .site-logo {
    color: #fff;
    padding-left: 0;
}

footer .site-logo::before {
    content: none;
}

.m-visible {
    display: none;
}


@media only screen and (max-width: 1200px) {
    .cta-table {
        padding: 17px 0;
    }
}

@media only screen and (max-width: 1000px) {
    h1 {
        font-size: 38px;
        line-height: 42px;
    }
    .nav-bar {
        gap: 40px;
    }
}

@media only screen and (max-width: 992px) {
    .td {
        grid-template-columns: 25% repeat(3, 1fr) 29% !important;
    }

    .table {
        margin-bottom: 55px;
    }

    .what-are {
        grid-template-columns: 1fr;
    grid-template-rows: 250px 1fr;
    }

    #what-are-p2e-nft-games {
        padding-top: 0px;
    }

    .earning::before {
        height: 888px;
    }

    .faq-wrapper {
        padding-top: 123px;
    }
    
    .left {
        padding-left: 25px;
    }
    .thead li:nth-of-type(4) {
        padding-left: 0;
    }
    .thead li:nth-of-type(2) {
        padding-left: 45px;
    }
    
    
}

@media only screen and (max-width: 768px) {
    h1 {
        font-size: 32px;
        line-height: 38px;
        margin-bottom: 24px;
    }

    h2 {
        font-size: 32px;
        line-height: 36px;
    }

    p {
        line-height: 26px;
    }

    .nav-bar {
        display: none;
    }
    
    .nav-wrapper {
        padding: 22px 0;
    }

    .site-header-inner {
        padding-top: 0;
        display: flex;
        flex-direction: column;
    }

    .hero-content {
        padding-bottom: 240px;
    }

    .site-header-inner::after,
    .site-header .container::before{
        content: none;
    }
    
    .hero-img::after {
        background-image: url(../image/hearts-mobile.svg);
        height: 120px;
        width: 40px;
        bottom: 35px;
        left: calc(50% - -50px);
    }

    .hero-img::before {
        left: calc(50% - 158px);
    }

    /* TABLE  */

    thead {
        display: none;
    }

    td {
        display: inline-block;
        padding: 10px;
    }

    tr td:first-child {
        width: 100%;
        border-radius: 10px 10px 0 0;
        padding-bottom: 20px;
    }

    .img a div {
        width: 100%;
        margin: 0;

    }
    tbody tr {
        background-color: transparent;
    }
    td {
        background-color: #ECF2F6;
        position: relative;
        z-index: 2;
        font-size: 16px;
        line-height: 22px;
    }

    td p {
        font-size: 16px;
line-height: 22px;
    }

    td:nth-of-type(2) {
        width: 100%;
    }
    td:nth-of-type(2) p {
        font-weight: 700;
    }
    td:nth-of-type(4) {
        width: 30%;
        padding-left: 0;
    }
    td:nth-of-type(3) {
        width: 70%;
        position: relative;
    }

    td:nth-of-type(3)::after {
        position: absolute;
        content: "";
        height: 100%;
        width: 1px;
        background-color: #fff;
        right: 20px;
    top: -5px;
    }
    td:last-of-type {
        width: 100%;
        border-radius: 0 0 10px 10px;
    }

    tr td:last-child {
        border-top-right-radius: 0px;
        padding-bottom: 20px;
    }

    /* .pading,
    .left {
        padding-left: 10px;
    } */

    td:nth-of-type(2)::before {
        position: absolute;
        content: "BLOCKCHAIN";
        top: -10px;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 30px;
        color: #758196;
    }
    td:nth-of-type(3)::before {
        position: absolute;
        content: "DEVICE";
        top: -10px;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 30px;
        color: #758196;
    }
    td:nth-of-type(4)::before {
        position: absolute;
        content: "STATUS";
        top: -13px;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 30px;
        color: #758196;
        height: 200%;
        background-color: #ECF2F6;
        width: 100%;
        z-index: -1;
    }

    table {
        margin-bottom: 20px;
    }


    .cta-table {
        width: 280px;
        margin: 15px auto 10px;
        display: flex;
        justify-content: center;
    }

    .activity .pading {
        max-width: none;
    }

    .what-are {
        display: flex;
        flex-direction: column;
    }

    .what-are-img {
        background-position: center;
        margin-right: 0;
        height: 263px;
        width: 100%;
    }

    .what-are h2 {
        padding-top: 0;
    }

    .coins {
        background-position: center;
        height: 140px;
        width: 100%;
    }

    .img-earning {
        display: none;
    }

    .earning {
        gap: 30px;
    }

    .earning::before {
        height: 900px;
    }
    .faq-wrapper {
        padding-top: 80px;
    }

    .faq-title h3 {
        font-weight: 400;
        font-size: 22px;
        line-height: 26px;
    }

    .about-us h2 {
        padding-top: 0;
    }

    .about-us {
        padding: 100px 0 40px;
    }


    /* TEST TABLE  */

    .thead {
        display: none !important;
    }

    .td {
        display: inline-block !important;
        width: 100%;
        padding-right: 10px !important;
    }

    .left {
        position: relative;
        display: inline-block;
        width: 100%;
        padding: 26px 15px 8px 21px;
    }
    
    .pading {
        width: 58%;
    }

    .status {
        width: 40%;
    }

    .img a div {
        margin-bottom: 15px;
    }

    .td div {
        position: relative;
    }

    .left::before {
        position: absolute;
        content: "BLOCKCHAIN";
        top: 2px;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 30px;
        color: #758196;
    }

    .pading::before {
        content: "DEVICE" !important;
    }

    .status::before {
        content: "STATUS" !important;
    }

    .status::after {
    position: absolute;
    content: "";
    height: 100%;
    width: 1px;
    background-color: #fff;
    left: 5px;
    top: 0px;
}


    /* READ MORE  */
    .m-slide-down {
        height: 0;
        overflow: hidden;
        -moz-transition: height 0.5s;
        -ms-transition: height 0.5s;
        -o-transition: height 0.5s;
        -webkit-transition: height 0.5s;
        transition: height 0.5s;
      }
      .m-hidden,
      .mobile-detector {
        display: none;
      }
      .m-visible {
        display: initial;
      }
      .m-hidden {
        display: initial;
      }
      .m-slide-down-measure p {
        margin: 0 !important;
      }
      .load-more{
          height: 49px;
      }
      
      .m-readmore-btn::before {
        cursor: pointer;
        display: inline-block;
        width: 25px;
        height: 25px;
        vertical-align: top;
        color: rgb(240, 240, 240);
        font-size: 24px !important;
      }
      
      .m-readmore-btn.read-more-2::before {
        content: "\002B";
      }
      .m-readmore-btn.collapse-2::before {
        content: "\2212";
      }
      
      .m-readmore-btn * {
        pointer-events: none;
      }
      .m-visible {
        display: initial;
      }
      
      .m-hidden {
          display: none;
      }
      .m-readmore-btn.read-more-3::before{
        content: "Load all games";
        text-transform: uppercase;
        cursor: pointer;
        text-transform: none !important;
        display: flex;
        justify-content: center;
        color: white;
        font-size: 24px !important;
        width: 100%;
          padding-top: 25px;
          padding-bottom: 25px;
          align-items: center;
          font-weight: 300;
          font-size: 16px;
          background-color: #404C52;
          border-radius: 10px;
          text-transform: uppercase;
      }
    
}

.load-more {
    background-color: #404C52;
    border-radius: 11px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 33px;
    color: #fff;
    text-transform: uppercase;
    cursor: pointer;
}

.read-more-2 {
    position: relative;
}

.read-more-2::after {
    position: absolute;
    content: "";
    background-image: url(../image/arrow-down.svg);
    width: 10px;
    height: 14px;
    background-size: contain;
    top: 3px;
    right: -15px;
}

.load-more .m-visible {
    display: initial !important;
}

@media only screen and (max-width: 565px){
.earning::before {
    height: 958px;
}
}

@media only screen and (max-width: 485px){
    .earning::before {
        height: 1056px;
    }
    }

@media only screen and (max-width: 405px){
        .earning::before {
            height: 1136px;
        }
        }


/* TEST TABLE  */



.thead,
.td {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    align-items: center;
    padding: 10px;
    text-align: center;
    
}

.thead {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 30px;
    color: #758196;
    text-transform: uppercase;
    padding: 20px 20px;
}

.td {
    background-color: #ecf2f6;
    border-radius: 20px;
    margin-bottom: 10px;
    padding-right: 30px;
}

.test-table th {
    padding: 0;
}

.middle {
    text-align: left;
}